/* Global */
:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000;
  --white: #fff;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}

.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}

.github-logo {
  width: 30px;
  height: 30px;
}

/* END GLOBAL SECTION */

/* Main Headings */

h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Body text */

.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills / about me */

.section-title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

/* Portfolio */

.sub-title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

/* Section Title Ends */

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: #fff;
  box-shadow: 0 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-items ul {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.navbar-items ul li a {
  text-decoration: none;
}

/* navbar content */
.navbar-content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.navbar-active-content {
  color: var(--primary);
}

.navbar-logo {
  width: 50px;
  padding-right: 80px;
}
/* Navbar style ends */

/* Hero Section */
.hero-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 150px 133.333px;
  align-items: center;
  justify-content: space-between;
  gap: 33px;
  background-color: var(--bg-shade);
}

.hero-section-content-box {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
}

.hero-section-content {
  display: flex;
  flex-direction: column;
  gap: 21.333px;
  align-items: flex-start;
}

.hero-section-content-box button {
  margin-top: 21.333px;
}

.hero-section-title {
  color: var(--heading-color);
  font-size: 74.67px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}

.hero-section-title-color {
  color: var(--primary);
}

.hero-section-title-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.hero-section-description {
  color: var(--black);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 5px;
}

.hero-section-img {
  display: flex;
  justify-content: center;
}

.hero-section-img img {
  width: 75%;
  height: 100%;
}

/* Hero Section Ends */

/*Skills section Style */
.skills-section {
  display: flex;
  padding: 149.33px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 106.667px;
}

.skills-section-heading {
  color: var(--heading-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
}

.skills-section-container {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  gap: 42.6px;
  /* Change the first value in repeat to change the number of columns */
  grid-template-columns: repeat(2, 1fr);
}
.skills-section-card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 375px;
  border-bottom: 4px solid transparent;
}

.skills-section-card:hover {
  border-bottom: 4px solid var(--primary);
}

.skills-section-card:hover .skills-section-description {
  color: var(--darkblue);
}

.skills-section-img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background-color: var(--white);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.skills-section-card-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  align-self: stretch;
}

.skills-section-title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}

.skills-section-description {
  color: var(--black);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
/* Skills section Ends */

/* About Us */
.about-section {
  display: grid;
  padding: 80px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}

.about-section-img img {
  margin-left: 10%;
  max-height: 80%;
  max-width: 80%;
}
/* About Us Ends */

/* My Portfolio Starts */
.portfolio-section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}

.portfolio-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}

.portfolio-section-container {
  display: flex;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}

.portfolio-section-img {
  border-radius: 8px;
  width: 100%;
}

.portfolio-section-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.portfolio-section-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  background: var(--white);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.portfolio-section-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 32px;
  padding: 32px;
}

.portfolio-section-title {
  color: var(--heading-color);
}

.portfolio-link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio-section-card:hover path {
  stroke: #006b6a;
}

.portfolio-section-description {
  height: 175px;
  padding: 10px 0;
}

.portfolio-link {
  text-decoration: none;
}
/* My Portfolio Ends */

/* Contact Me */
.contact-section {
  display: flex;
  padding: 10px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}

.contact-form-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 32px;
}

.contact-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}

.contact-input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  margin: 5px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}

#message {
  height: 100px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px);
}
/*For IE*/
select::-ms-expand {
  display: none;
}
/* Contact Ends */

/* Footer Starts */
.footer-container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer-items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.footer-items {
  cursor: pointer;
}

.footer-items ul > li > a {
  text-decoration: none;
}
.footer-social-icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer-content {
  color: var(--black);
  font-size: 15.667px;
  font-weight: 400;
  line-height: 28px;
}

.attribute-link {
  color: var(--darkblue);
  text-decoration: none;
}

.footer-logo {
  width: 50px;
  padding-right: 100px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero-section-title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills-section-title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills-section-heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills-section-container {
    gap: 16px;
  }
  .skills-section-card {
    gap: 28px;
  }
  .skills-section-card-content {
    gap: 20px;
  }
  .skills-section-title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills-section-description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero-section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .hero-section-title,
  .hero-section-description,
  .footer-content {
    text-align: center;
  }
  .skills-section-container,
  .about-section,
  .portfolio-section-container,
  .portfolio-container-box,
  .footer-link-container,
  .footer-items > ul,
  .footer-content-container {
    display: flex;
    flex-direction: column;
  }
  .skills-section,
  .portfolio-container,
  .hero-section-content,
  .hero-section-content-box {
    align-items: center;
  }
  .portfolio-container-box {
    gap: 30px;
  }
  .skills-section,
  .testimonial-section,
  .contact-section,
  .footer-container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about-section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial-section-card {
    padding: 25px;
  }
  .hero-section-title,
  .skills-section-heading,
  .sections-heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero-section-content-box {
    gap: 10px;
  }
  .contact-form-container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer-social-icon > ul,
  .footer-items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }

  .hero-section-img {
    max-width: 75%;
  }
}

/* Hamburger menu  */

.nav_hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav_hamburger_line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav_hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar-items {
    display: flex;
  }
  .navbar-items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar-items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar-items ul li {
    text-align: center;
  }
  .navbar-items ul li a {
    padding: 0.5rem;
  }
  .navbar-items {
    z-index: -1000;
  }
  .navbar-items.active {
    top: 30px;
  }
  .nav_hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav_hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav_hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }

  .footer-logo,
  .navbar-logo {
    padding: 0;
    margin: 0;
  }

  .navbar {
    padding: 15px 25px;
  }

  .portfolio-section-description {
    height: 245px;
  }

  .hero-section .section-title {
    display: block;
  }
}

/* Larger Resolutions */
@media only screen and (max-width: 450px) {
  .portfolio-section-description {
    height: 310px;
  }
}

@media only screen and (max-width: 390px) {
  .portfolio-section-description {
    height: 400px;
  }
}

@media only screen and (min-width: 1200px) {
  .hero-section-content-box {
    margin-left: 100px;
  }
}

/* Responsive Screens Ends*/

/* Typewriter effect found here: https://css-tricks.com/snippets/css/typewriter-effect/ */
.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.12em solid black; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  font-family: "Roboto", sans-serif;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
